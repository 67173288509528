import $ from "jquery";
//import "slick-carousel";
import "./polyfills";



const prefillValues = {
  light: {
    phase_study_type: 1,
    number_of_sites: 1,
    sponsor_type: 1,
    number_of_invest: 500
  },
  pro: {
    phase_study_type: 2,
    number_of_sites: 10,
    sponsor_type: 2,
    number_of_invest: 1000
  },
  premium: {
    phase_study_type: 3,
    number_of_sites: 100,
    sponsor_type: 3,
    number_of_invest: 10000
  },
  custom: {
    phase_study_type: "none",
    number_of_sites: 0,
    sponsor_type: "none",
    number_of_invest: 0
  }
};

const initNavigation = () => {
  const navigationBurgerEl = document.querySelector(".js-main-nav-burger");
  const bodyEl = document.body;
  navigationBurgerEl.addEventListener("click", () => {
    if (bodyEl.classList.contains("is-main-nav-opened")) {
      document.body.classList.remove("is-main-nav-opened");
    } else {
      document.body.classList.add("is-main-nav-opened");
    }
  });
};

const preFillInputs = type => {
  const inputEls = document.querySelectorAll(".js-prefill-input");
  inputEls.forEach(input => {
    input.value = prefillValues[type][input.name];
  });
};

const radiosInit = () => {
  const radioEls = document.querySelectorAll(
      '.js-choices-tab-wrapper input[name="radio-tab"][type="radio"]'
  );

  radioEls.forEach(radioItem => {
    radioItem.addEventListener("click", function(event) {
      preFillInputs(event.target.dataset.prefill_type);
    });
  });
};

document.addEventListener("DOMContentLoaded", function() {
  radiosInit();
  preFillInputs("light");
  initNavigation();
});

/*
const scrollButton = document.querySelector(".js-scroll-to-top");

function scrollToTop(event) {
  event.preventDefault();
  window.scrollTo(0, 0);
}

scrollButton.addEventListener("click", scrollToTop);
*/

window.$ = $